import React, { useEffect } from "react";
import Pollen from "./pollen";
import "./App.css";
import lglLogo from "./images/logo_lgl_schriftzug.png"
import zaumLogo from "./images/ZAUM_Logo_schwarz.jpg"
import hhLogo from "./images/logo_helmholtz_munich.png"
import tumLogo from "./images/logo_tum_small.gif"
import Marquee from './marquee';


function App() {

    useEffect(() => {
        // Function to refresh the page
        const refreshPage = () => {
            window.location.reload();
        };

        // Set interval to refresh the page every one hour (3600000 milliseconds)
        const intervalId = setInterval(refreshPage, 3600000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures this effect runs only once after the component mounts


  return (
    <React.Fragment>
      <div className="container">
              <Pollen />
          </div>

          

          <div className="footer-info">
              {<Marquee />}
              <ul>
                  <li> <img class="logo" src={zaumLogo} /> Zentrum Allergie und Umwelt (ZAUM)</li>
                  <li><img class="logo" src={hhLogo} /></li>
                  <li><img class="logo" src={tumLogo} /></li>
                  <li><img class="logo" src={lglLogo} /></li>
              </ul>

              
          </div>

        

    </React.Fragment>
  );
}

export default App;
