import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./App.css";

const Marquee = () => {
    const [text, setText] = useState('Loading...');


    axios.get('/announcement.txt')
        .then(response => {
            setText(response.data);
        })
        .catch(error => {
            console.error('Error fetching text:', error);
            setText('Error loading text.');
        });

    useEffect(() => {

        const readFileTimer = setInterval(() => { 

        axios.get('/announcement.txt')
            .then(response => {
                setText(response.data);
            })
            .catch(error => {
                console.error('Error fetching text:', error);
                setText('Error loading text.');
            });

        }, 600000);

        return () => {
            clearInterval(readFileTimer);
        };

    }, []);

    return (
        <React.Fragment>
            <div className="marquee">
                <div className="marquee-content">{text}</div>
            </div>
        </React.Fragment>
    );
}

export default Marquee;