import React, { useState, useEffect } from "react";
import axios from "axios";
import apiKeys from "./apiKeys";
import ReactAnimatedWeather from "react-animated-weather";
import epin_network from "./images/epin_network.png";
import qrFeedBack from "./images/qr_feed.png";


function Forcast(props) {
    const [query, setQuery] = useState("");
    const [error, setError] = useState("");
    const [weather, setWeather] = useState({});
    const [airState, setAirState] = useState([]);

    const searchWeather = (city) => {
        axios
            .get(
                `${apiKeys.base}weather?lang=de&q=${city !== "[object Object]" ? city : query
                }&units=metric&APPID=${apiKeys.key}`
            )
            .then((response) => {
                setWeather(response.data);
                setQuery("");
            })
            .catch(function (error) {
                console.log(error);
                setWeather("");
                setQuery("");
                setError({ message: "Not Found", query: query });
            });
    };

    const getAirQuality = async () => {
        const api_call = await fetch(`https://inters.bayern.de/TUM/TUM.json`);
        const dataair = await api_call.json();
        setAirState(dataair);
    };

    const defaults = {
        color: "white",
        size: 112,
        animate: true,
    };

    const importAllImages = (r) => {
        return r.keys().map(r);
    }

    const setPollenBackgroundImage = (path) => {

        const element = document.getElementById('pollenBackground');

        if (element) {
            element.style.background = `url(${process.env.PUBLIC_URL + path})`;
            element.style.backgroundSize = "fill";
            element.style.backgroundRepeat = "no-repeat";
            element.style.backgroundPosition = "50% 50%";
            element.style.minHeight = "500px";
            element.style.position = "relative";
            element.style.webkitBackgroundSize = "cover";
            element.style.float = "left";
            element.style.height = "100%";
            element.style.width = "60%";
            element.style.objectFit = "scale-down";
            element.style.backgroundColor = "transparent";
        }
    }

    useEffect(() => {
        let city = "Munich"
        searchWeather(city);
        getAirQuality();

        const pollenBackggroundImages = importAllImages(require.context('./images/pollen-background', false, /\.(png|jp?g|svg)$/));
        setPollenBackgroundImage(pollenBackggroundImages[0]);

        const airQualityTimer = setInterval(() => {getAirQuality();}, 60000);
        const weatherTimer = setInterval(() => { searchWeather(city); }, 60000);

        const pollenBakcgTimer = setInterval(() => { setPollenBackgroundImage(pollenBackggroundImages[Math.floor(Math.random() * pollenBackggroundImages.length)]); }, 6000);


        return () => {
            clearInterval(airQualityTimer);
            clearInterval(weatherTimer);
            clearInterval(pollenBakcgTimer);
        };
    }, []);

    return (
        <div className="forecast">
            <div className="forecast-icon">
                <ReactAnimatedWeather
                    icon={props.icon}
                    color={defaults.color}
                    size={defaults.size}
                    animate={defaults.animate}
                />
            </div>
            <div className="today-weather">
                <h3>{props.weather}</h3>
                
                <ul>
                    {typeof weather.main != "undefined" ? (
                        <div>
                            {" "}
                            <li className="cityHead">
                                <p>
                                    {weather.name}, {weather.sys.country}
                                </p>
                                <img
                                    className="temp"
                                    src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}.png`}
                                    alt={weather.weather[0].icon}
                                />
                            </li>
                            <li>
                                Temperatur
                                <span className="temp">
                                    {Math.round(weather.main.temp)}°c
                                </span>
                            </li>
                            <li>
                                Luftfeuchte
                                <span className="temp">
                                    {Math.round(weather.main.humidity)}%
                                </span>
                            </li>
                            <li>
                                Sichtweite
                                <span className="temp">
                                    {Math.round(weather.visibility)} m
                                </span>
                            </li>
                            <li>
                                Windgeschwindigkeit
                                <span className="temp">
                                    {Math.round(weather.wind.speed)} km/h
                                </span>
                            </li>
                            <li>
                            <span className="temp">
                               
                                </span>
                            </li>
                        </div>
                    ) : (
                        <li>
                            {error.query} {error.message}
                        </li>
                    )}

                    {typeof airState.headline != "undefined" ? (
                        <div>
                    <li>
                        Ozon-O3
                        <span className="temp">
                            {Math.round((airState.headline.MWO3 * 80) / 180,0)} ug/m3
                                </span>
                    </li>
                    <li>
                        Stickoxide-NO2
                        <span className="temp">
                            {Math.round((airState.headline.MWNO2 * 80) / 200,0)} ug/m3
                                </span>
                    </li>
                    <li>
                        Feinstaub-PM10
                        <span className="temp">
                            {Math.round((airState.headline.MWPM10 * 80) / 50, 0)} ug/m3
                        </span>
                            </li>
                        </div>
                    ) : (
                        <li>
                            {error.query} {error.message}
                            </li>

                    )}

                </ul>
            </div>

            <div className="epin">
                {/* <img className="temp" src={epin_network} alt={"ePIN Network"} /> */}
                <img src={qrFeedBack} alt={"Feed back"} />
            </div>

        </div>
    );
}
export default Forcast;
